import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Logout from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import fire from '../../../assets/images/christmas-fire.svg';
import tree from '../../../assets/images/christmas-tree.svg';
import cartIcon from '../../../assets/images/icon/cart.svg';
import logo from '../../../assets/images/logo.svg';
import tech5 from '../../../assets/images/technology/affiliate.png';
import tech8 from '../../../assets/images/technology/branding.png';
import tech6 from '../../../assets/images/technology/mobile-app.png';
import tech4 from '../../../assets/images/technology/shopify.png';
import tech7 from '../../../assets/images/technology/social-media.png';
import tech3 from '../../../assets/images/technology/wordpress.png';
import { logoutUser } from '../../../redux/reducers/authReducer.slice';
import { getCart } from '../../../redux/reducers/cartReducer.slice';
import './header.scss';
import useScrollDirection from './usescrollDirection';
import Announcement from '../Announcement/announcement';
import search from '../../../assets/images/search.svg';
import { getSreachResult } from '../../../services/pages.service';
import useDebounce from '../../../utils/debounceSearch/useDebounce';
import Skeleton from 'react-loading-skeleton';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const logoutHandler = () => {
    dispatch(logoutUser());
    navigate('/');
  };
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart);
  const menu = useSelector((state) => state.commonData.menuData);
  const [searchVal, setSearchVal] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [showCross, setShowCross] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const userId = useSelector((state) => state.user.user_id);
  const debouncedSearch = useDebounce(searchVal, 600);
  const [loadingSearch, setLoadingSearch] = useState(false);
  useEffect(() => {
    if (user.user_id) {
      getSearch();
    }
  }, [debouncedSearch]);

  const getSearch = async (fromAction = false) => {
    const searchValue = searchVal ? searchVal : '';
    setSearchVal(searchValue);

    setLoadingSearch(true);
    let searchResult = await getSreachResult(
      { search: searchValue, userId: userId },
      dispatch,
      navigate
    );

    if (searchResult.data.status == 404) {
      setSearchData([]);
      setErrorMessage('No data found');
    } else {
      const items = searchResult.data;
      const final = items.data.filter((v) => v.banner_image);
      setSearchData(final);
      setErrorMessage(null);
    }
    setLoadingSearch(false);
  };

  const handleData = (item) => {
    const object = {
      id: item.slug,
      path: '/item' + item.theme_name
    };
  };

  useEffect(() => {
    if (!showCross) {
      if (user.user_id) {
        getSearch();
      }
    }
  }, [showCross]);

  useEffect(() => {
    // Function to handle click event on card search
    const handleClick = (e) => {
      let searchEle = document.getElementById('search-card');
      if (searchEle) {
        if (searchEle.contains(e.target)) {
        } else {
          setSearchVal('');
        }
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const scrollDirection = useScrollDirection();
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (name) => {
    setAnchorEl(null);
    switch (name) {
      case 'profile':
        navigate('/profile');
        break;
      case 'settings':
        navigate('/settings');
        break;
      case 'downloads':
        navigate('/downloads');
        break;
      case 'favorites':
        navigate('/favorites');
        break;
      default:
        break;
    }
  };

  const cartItems = useSelector((state) => state.cart);
  let couponCode = '';
  useEffect(() => {
    (async () => {
      try {
        const getlist = await dispatch(
          getCart(
            {
              cart_key: cartItems?.sessionKey,
              coupon_code: couponCode
            },
            dispatch,
            navigate
          )
        );
      } catch (error) {}
    })();
  }, []);

  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);

  // handle scroll event
  const handleScroll = (elTopOffset, elHeight) => {
    if (window.pageYOffset > elTopOffset + elHeight) {
      setSticky({ isSticky: true });
    } else {
      setSticky({ isSticky: false });
    }
  };

  const [timer, setTimer] = useState(null);

  const calculateTimeRemaining = () => {
    const targetDate = new Date('2023-12-26T00:00:00'); // December 26, 2023
    const now = new Date();
    const difference = targetDate - now;

    if (difference > 0) {
      setTimer(difference);
    } else {
      setTimer(null);
    }
  };

  const formatNumber = (number) => {
    return number < 10 ? `0${number}` : `${number}`;
  };

  const calculateTimeRemainingFn = () => {
    const targetDate = new Date('January 15, 2024 00:00:00').getTime();

    const now = new Date().getTime();
    const difference = targetDate - now;

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    setTimer({
      days: formatNumber(days) >= 0 ? formatNumber(days) : 0,
      hours: formatNumber(hours) >= 0 ? formatNumber(hours) : 0,
      minutes: formatNumber(minutes) >= 0 ? formatNumber(minutes) : 0,
      seconds: formatNumber(seconds) >= 0 ? formatNumber(seconds) : 0
    });
  };

  // useEffect(() => {
  //   calculateTimeRemaining();
  //   console.log(timer);
  // }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      calculateTimeRemainingFn();
    }, 1000);

    return () => clearInterval(timer);
  }, []);
  // add/remove scroll event listener
  useEffect(() => {
    var header = headerRef.current.getBoundingClientRect();
    const handleScrollEvent = () => {
      handleScroll(header.top, header.height);
    };
    window.addEventListener('scroll', handleScrollEvent);
    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, []);
  // console.log(menu);
  return (
    <header className={`${scrollDirection === 'down' ? 'hide' : 'show'}`} ref={headerRef}>
      <Announcement />
      {/* {timer ? (
        <div
          className="row"
          style={{
            width: '100%',
            background: '#FFB43A',
            margin: 0,
            padding: '5px 0px',
            color: '#2d2d2d',
            fontWeight: '600'
          }}
        >
          <div className="col-md-12">
            <div className="timer">
              <img className="hide-mob" style={{ width: '30px' }} src={tree} alt="Logo" />
              <span className="hide-sm-mob">New Year</span>{' '}
              <span className="sale-50">SALE 50%</span>{' '}
              <span className="hide-sm-mob">On All Templates</span>{' '}
              <img className="hide-mob" style={{ width: '30px' }} src={tree} alt="Logo" />
              <span className="hide-mob">Hurry</span>{' '}
              <img className="hide-mob" style={{ width: '30px' }} src={fire} alt="Logo" />{' '}
              <span className="hide-mob">Now</span>
              <div className="d-flex" style={{ gap: '5px' }}>
                <span className="timer-count">{timer.days}</span>{' '}
                <span className="timer-colon">:</span>
                <span className="timer-count">{timer.hours}</span>{' '}
                <span className="timer-colon">:</span>
                <span className="timer-count">{timer.minutes}</span>{' '}
                <span className="timer-colon">:</span>
                <span className="timer-count">{timer.seconds}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )} */}

      <nav
        className={`${
          sticky.isSticky ? ' sticky navbar navbar-expand-xl' : 'navbar navbar-expand-xl'
        }`}
      >
        <div className="container">
          <div className="logo">
            <Link to="/" className="navbar-brand">
              <img style={{ width: '100%' }} src={logo} alt="Logo" />
            </Link>
          </div>
          <div className="right_wrapper">
            <div className="navbar_block d-none d-md-block">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar"
              >
                <svg viewBox="0 0 64 48">
                  <path d="M19,15 L45,15 C70,15 58,-2 49.0177126,7 L19,37"></path>
                  <path d="M19,24 L45,24 C61.2371586,24 57,49 41,33 L32,24"></path>
                  <path d="M45,33 L19,33 C-8,33 6,-2 22,14 L45,37"></path>
                </svg>
              </button>
              <div
                className="offcanvas offcanvas-end"
                tabIndex="-1"
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
              >
                <div className="offcanvas-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body">
                  <ul className="navbar-nav tabActive navbar-nav-scroll">
                    {/* <li className="nav-item">
                      <Link
                        className={`nav-link ${location.pathname === '/' ? 'active' : ''}`}
                        to="/"
                        data-bs-dismiss="offcanvas"
                      >
                        Home
                      </Link>
                    </li> */}
                    {user.user_id && (
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === `/category/${menu[1]?.slug}` ? 'active' : ''
                          }`}
                          to={{ pathname: `/category/${menu[1]?.slug}` }}
                          data-bs-dismiss="offcanvas"
                          state={{ name: menu[1]?.theme_categorie }}
                        >
                          Free Templates
                        </Link>
                      </li>
                    )}
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === `/category/${menu[0]?.slug}` ? 'active' : ''
                        }`}
                        to={{ pathname: `/category/${menu[0]?.slug}` }}
                        data-bs-dismiss="offcanvas"
                        state={{ name: menu[0]?.theme_categorie }}
                      >
                        HTML
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className={`nav-link ${
                          location.pathname === `/category/${menu[5]?.slug}` ? 'active' : ''
                        }`}
                        to={{ pathname: `/category/${menu[5]?.slug}` }}
                        data-bs-dismiss="offcanvas"
                        state={{ name: menu[5]?.theme_categorie }}
                      >
                        Social media
                      </Link>
                    </li>
                    {
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${
                            location.pathname === '/supportticket' ? 'active' : ''
                          }`}
                          to="/supportticket"
                          data-bs-dismiss="offcanvas"
                        >
                          Support Ticket
                        </Link>
                      </li>
                    }
                    {/* {
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${location.pathname === `/Firststep` ? 'active' : ''
                            }`}
                          to={{ pathname: `/Firststep` }}
                          data-bs-dismiss="offcanvas"
                        >
                          steps
                        </Link>
                      </li>
                    } */}
                    {/* {
                      <li className="nav-item">
                        <Link
                          className={`nav-link ${location.pathname === `/search` ? 'active' : ''}`}
                          to={{ pathname: `/search` }}
                          data-bs-dismiss="offcanvas"
                        >
                          <img src={search} />
                        </Link>
                      </li>
                    } */}
                    {user.user_id ? (
                      <>
                        <li>
                          <div className="position-relative">
                            <input
                              type="search"
                              placeholder="Search..."
                              className="input-search"
                              value={searchVal}
                              onChange={(e) => {
                                setSearchVal(e.target.value);
                              }}
                            />
                            {searchVal ? (
                              <span
                                onClick={() => {
                                  setSearchVal('');
                                  setShowCross(false);
                                }}
                                className="search-cross"
                              >
                                x
                              </span>
                            ) : (
                              <>
                                <button className="search-btn" type="submit">
                                  <img src={search} />
                                </button>
                              </>
                            )}
                            {searchVal.length > 0 && (
                              <div id="search-card" className="search-card">
                                {loadingSearch ? (
                                  <>
                                    <Skeleton count={1} className="h1-skeleton" />
                                    <Skeleton count={1} className="h1-skeleton" />
                                    <Skeleton count={1} className="h1-skeleton" />
                                    <Skeleton count={1} className="h1-skeleton" />
                                    <Skeleton count={1} className="h1-skeleton" />
                                    <Skeleton count={1} className="h1-skeleton" />
                                    <Skeleton count={1} className="h1-skeleton" />
                                    <Skeleton count={1} className="h1-skeleton" />
                                  </>
                                ) : (
                                  <>
                                    {searchData.length === 0 ? (
                                      <>
                                        <h4 className="text-center mb-0">No Data Found!</h4>
                                      </>
                                    ) : (
                                      <>
                                        <ul>
                                          {searchData &&
                                            searchData.map((category, index) => (
                                              <React.Fragment key={index}>
                                                <li tabIndex="1">
                                                  <a
                                                    href={`/item/${category.slug}`}
                                                    onClick={() => handleData(category)}
                                                  >
                                                    <span>
                                                      <img
                                                        src={category.featured_image}
                                                        alt="Card Thumbnail"
                                                      />
                                                    </span>
                                                    {category.theme_name}
                                                  </a>
                                                </li>
                                              </React.Fragment>
                                            ))}
                                        </ul>
                                      </>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                          </div>
                        </li>
                      </>
                    ) : (
                      <></>
                    )}

                    {/* CATEGORIES IS HIDDEN FOR NOW: PLEASE REMOVE d-none CLASS WHEN NEED TO SHOW*/}
                    <li className="nav-item dropdown has-megamenu d-none">
                      <Link
                        className="nav-link dropdown-toggle"
                        to="#"
                        id="navbarDropdown1"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        Categories
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5931 5.66675L8.92643 10.3334L4.25977 5.66675"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Link>
                      <ul
                        className="dropdown-menu megamenu d-none"
                        aria-labelledby="navbarDropdown1"
                      >
                        <div className="category_modal">
                          <div className="top_modal">
                            <div className="row">
                              <div className="col-xl-12 col-12">
                                <div className="col_megamenu">
                                  <ul className="tech_temp">
                                    {menu?.map((menu, i) => (
                                      <li key={i}>
                                        <div className="tech_img ht">
                                          <img
                                            src={
                                              menu?.category_image ||
                                              'https://dummyimage.com/48x48/f208f2/ffffff'
                                            }
                                            alt="HTML Logo"
                                          />
                                        </div>
                                        <div className="head_title">
                                          <Link
                                            to={{ pathname: `/category/${menu.cate_id}` }}
                                            state={{ name: menu?.theme_categorie }}
                                          >
                                            {menu?.theme_categorie}
                                          </Link>
                                          <p>
                                            {menu?.category_description ||
                                              'It is a long established fact that a reader will be distracted by'}
                                          </p>
                                        </div>
                                      </li>
                                    ))}
                                    {/* <li>
                                      <div className="tech_img ht">
                                        <img src={tech1} alt="HTML Logo" />
                                      </div>
                                      <div className="head_title">
                                        <a href="/">HTML Templates</a>
                                        <p>
                                          It is a long established fact that a reader will be
                                          distracted by{' '}
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="tech_img woo">
                                        <img src={tech2} alt="WooCommerce Theme" />
                                      </div>
                                      <div className="head_title">
                                        <a href="/">Woocommerce Theme</a>
                                        <p>
                                          It is a long established fact that a reader will be
                                          distracted by{' '}
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="tech_img wp">
                                        <img src={tech3} alt="WordPress Theme" />
                                      </div>
                                      <div className="head_title">
                                        <a href="/">Wordpress Theme</a>
                                        <p>
                                          It is a long established fact that a reader will be
                                          distracted by{' '}
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="tech_img sp">
                                        <img src={tech4} alt="Shopify Theme" />
                                      </div>
                                      <div className="head_title">
                                        <a href="/">Shopify theme</a>
                                        <p>
                                          It is a long established fact that a reader will be
                                          distracted by{' '}
                                        </p>
                                      </div>
                                    </li>  */}
                                  </ul>
                                </div>
                              </div>
                              <div className="col-xl-6 col-12 d-none">
                                <div className="col_megamenu">
                                  <ul className="tech_temp">
                                    <li>
                                      <div className="tech_img wp">
                                        <img src={tech3} alt="WordPress Theme" />
                                      </div>
                                      <div className="head_title">
                                        <a href="/">Wordpress Theme</a>
                                        <p>
                                          It is a long established fact that a reader will be
                                          distracted by{' '}
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <div className="tech_img sp">
                                        <img src={tech4} alt="Shopify Theme" />
                                      </div>
                                      <div className="head_title">
                                        <a href="/">Shopify theme</a>
                                        <p>
                                          It is a long established fact that a reader will be
                                          distracted by{' '}
                                        </p>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bottom_modal d-none">
                            <div className="row">
                              <div className="col-xl-6 col-12">
                                <div className="col_megamenu">
                                  <ul className="prototypes">
                                    <li>
                                      <div className="tech_img">
                                        <img src={tech5} alt="Affliate Themes" />
                                      </div>
                                      <a href="/">Affiliate templates</a>
                                    </li>
                                    <li>
                                      <div className="tech_img">
                                        <img src={tech6} alt="Mobile" />
                                      </div>
                                      <a href="/">Mobile app prototype</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="col-xl-6 col-12">
                                <div className="col_megamenu">
                                  <ul className="prototypes">
                                    <li>
                                      <div className="tech_img">
                                        <img src={tech7} alt="Social Media" />
                                      </div>
                                      <a href="/">Social media post bundle</a>
                                    </li>
                                    <li>
                                      <div className="tech_img">
                                        <img src={tech8} alt="Brand Bundle" />
                                      </div>
                                      <a href="/">Full Branding bundle</a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </ul>
                    </li>
                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/">
                        Pricing
                      </Link>
                    </li> */}
                    {/* <li className="nav-item">
                      <Link
                        className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}
                        to="/contact"
                        data-bs-dismiss="offcanvas"
                      >
                        Contact Us
                      </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="buttonBlock">
              {user.user_id ? (
                <>
                  <div className="profile_nav">
                    <li className="nav-item dropdown">
                      <Tooltip title="Account settings">
                        <IconButton
                          onClick={handleClick}
                          size="small"
                          sx={{ ml: 2 }}
                          aria-controls={open ? 'account-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                        >
                          <Avatar sx={{ width: 32, height: 32 }}>
                            {user.user_login.substring(0, 1).toUpperCase()}
                          </Avatar>
                          {/* <span className="ms-2" style={{ textTransform: 'capitalize' }}> {user.user_login} </span> */}
                        </IconButton>
                      </Tooltip>
                      <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1
                            },
                            '&:before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0
                            }
                          }
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                      >
                        <MenuItem onClick={() => handleClose('profile')}>
                          <ListItemIcon>
                            <PersonOutlineIcon fontSize="small" />
                          </ListItemIcon>
                          My Account
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => logoutHandler('logout')}>
                          <ListItemIcon>
                            <Logout fontSize="small" />
                          </ListItemIcon>
                          Logout
                        </MenuItem>
                      </Menu>

                      <Link
                        className="nav-link dropdown-toggle d-none"
                        to="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <img src={profileIcon} alt="User Icon" /> */}
                        <AccountCircleOutlinedIcon />
                        <svg
                          width="17"
                          height="16"
                          viewBox="0 0 17 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.5931 5.66675L8.92643 10.3334L4.25977 5.66675"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </Link>
                      <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li>
                          <Link className="dropdown-item" to="/profile">
                            Profile
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/settings">
                            Settings
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/downloads">
                            Downloads
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/favourites">
                            Favorites
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/collections">
                            Collections
                          </Link>
                        </li>
                        <li>
                          <button className="dropdown-item" to="/" onClick={logoutHandler}>
                            Log Out
                          </button>
                        </li>
                      </ul>
                    </li>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-none d-md-flex align-items-center btns">
                    <Link to="/login" className="btn_wrapper">
                      Sign in
                    </Link>
                    <Link to="/signup" className="btn_wrapper light">
                      Sign Up
                    </Link>
                  </div>
                  <div className="d-flex d-md-none align-items-center btns mobile-btn">
                    <Link to="/login" className="btn_wrapper">
                      Sign in
                    </Link>
                  </div>
                </>
              )}
              <Link to="/cart" className="d-none d-md-block">
                <div className="cart_wrapper position-relative">
                  <img src={cartIcon} alt="Cart Icon" />
                  {cart.items.length !== 0 && (
                    <div className="notification">{cart.items.length || 0}</div>
                  )}
                </div>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
